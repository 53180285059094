import Vue from 'vue'
import VueRouter from 'vue-router'
import { Notify } from 'vant';
import { Toast } from 'vant';
Vue.use(Notify)
Vue.use(Toast)
Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/login',
  //   name: 'Login',
  //   component: () => import('../views/Login.vue'),
  //   meta: {
  //     title: '登录界面'
  //   }
  // },
  // {
  //   path: '/register',
  //   name: 'Register',
  //   component: () => import('../views/Register.vue'),
  //   meta: {
  //     title: '注册界面'
  //   }
  // },
  {
    path: '/',
    name: 'Index',
    component: () => import('../views/Index.vue'),
    meta: {
      title: '首页'
    }
  },
  {
    path: '/borrow',
    name: 'Borrow',
    component: () => import('../views/Borrow.vue'),
    meta: {
      title: '借阅'
    }
  },
  {
    path: '/renew',
    name: 'Renew',
    component: () => import('../views/Renew.vue'),
    meta: {
      title: '我的在借'
    },
  },
  {
    path: '/borrowRecord',
    name: 'borrowRecord',
    component: () => import('../views/borrowRecord.vue'),
    meta: {
      title: '借阅记录'
    },
  },
  {
    path: '/renewing',
    name: 'Renewing',
    component: () => import('../views/Renewing.vue'),
    meta: {
      title: '续借'
    }
  },
  {
    path: '/subtenancy',
    name: 'Subtenancy',
    component: () => import('../views/Subtenancy.vue'),
    meta: {
      title: '转借'
    }
  },
  {
    path: '/cursubtenancy',
    name: 'Cursubtenancy',
    component: () => import('../views/Cursubtenancy.vue'),
    meta: {
      title: '确认转借'
    }
  },
  {
    path: '/changepassword',
    name: 'Changepassword',
    component: () => import('../views/Changepassword.vue'),
    meta: {
      title: '修改密码'
    }
  },
  {
    path: '/reserve',
    name: 'Reserve',
    component: () => import('../views/Reserve.vue'),
    meta: {
      title: '图书预约'
    }
  },
  {
    path: '/advance',
    name: 'Advance',
    component: () => import('../views/Advance.vue'),
    meta: {
      title: '图书预借'
    }
  },
  {
    path: '/card',
    name: 'Card',
    component: () => import('../views/Card.vue'),
    meta: {
      title: '借阅证'
    }
  },
  {
    path: '/my',
    name: 'My',
    component: () => import('../views/My.vue'),
    meta: {
      title: '我的预约/预借'
    }
  },
  {
    path: '*',
    redirect: { name: 'Index' }
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  /**
   * 缓存记录页面初始（即授权前）路径
   * 主要用于解决在页面手动刷新时，微信授权问题
   * hash模式：let href = window.location.origin + window.location.pathname + '#' + to.fullPath
   * location：let href = window.location.origin + window.location.pathname + to.fullPath
   */
  // let href = window.location.origin + window.location.pathname + '#' + to.fullPath;
  // console.log(window.location.origin);
  // console.log(window.location.pathname);
  // console.log(to.fullPath);
  // window.localStorage.setItem('WXAuthorizeUrl', href);
  // console.log(href);
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})



export default router

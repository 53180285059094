import axios from '@/utils/myaxios.js'


//获取登录信息
export const getCode = (obj) => {
  return axios({
    method: 'get',
    url: '/wechat/query',
    params: obj,

  })
}
// 登录
export const loginApi = (obj) => {
  return axios({
    method: 'post',
    url: '/wechat/register/checkUser',
    params: obj
  })
}

export const getWXConfig = (obj) => {
  return axios({
    method: 'get',
    url: '/wechat/book/qrCode',
    params: obj
  })
}

// 注册
export const registerApi = (obj) => {
  return axios({
    method: 'post',
    url: '/wechat/register/doRegister',
    params: obj
  })
}
// 注册信息
export const registerInfo = (obj) => {
  return axios({
    method: 'get',
    url: '/wechat/register/goRegister',
    params: obj
  })
}
// 图书借阅
export const borrow = (obj) => {
  return axios({
    method: 'post',
    url: '/wechat/book/checkout',
    params: obj
  })
}
//查询在借
export const renewData = () => {
  return axios({
    method: 'get',
    url: '/wechat/book/getQueryDataBorrow',
    // params: obj
  })
}
//借阅记录
export const borrowRecord = () => {
  return axios({
    method: 'get',
    url: '/wechat/book/getQueryData',
    // params: obj
  })
}
//续借
export const renew = (obj) => {
  return axios({
    method: 'post',
    url: '/wechat/book/renew',
    params: obj
  })
}


//借阅证
export const getCard = (obj) => {
  return axios({
    method: 'get',
    url: '/wechat/book/toCard',
    params: obj
  })
}

//修改密码
export const updatePassword = (obj) => {
  return axios({
    method: 'post',
    url: '/wechat/register/updPwd',
    data: obj
  })
}

//转借
export const underlease = (obj) => {
  return axios({
    method: 'post',
    url: '/wechat/book/underlease',
    params: obj
  })
}
//确认转借
export const curunderlease = (obj) => {
  return axios({
    method: 'post',
    url: '/wechat/book/performLoan',
    params: obj
  })
}
//预借图书查询
export const reserveList = (obj) => {
  return axios({
    method: 'get',
    url: '/wechat/book/getborrowinbookList',
    params: obj
  })
}

//预借
export const reserve = (obj) => {
  return axios({
    method: 'post',
    url: '/wechat/book/getBorrowinbookbook',
    params: obj
  })
}
//查询图书列表
export const searchBook = (obj) => {
  return axios({
    method: 'get',
    url: '/wechat/book/getpbespeakbookList',
    params: obj
  })
}
//预约
export const order = (obj) => {
  return axios({
    method: 'post',
    url: '/wechat/book/getpbespeakbook',
    params: obj
  })
}
//我的预约
export const myorder = () => {
  return axios({
    method: 'get',
    url: '/wechat/book/myPbespeakbook'
  })
}
//取消预约
export const cancelorder = (obj) => {
  return axios({
    method: 'get',
    url: '/wechat/book/cancelPbespeakbook',
    params: obj
  })
}
//退出登录
export const logOut = (obj) => {
  return axios({
    method: 'get',
    url: '/wechat/register/logout ',

  })
}
//解绑公众号 
export const untieAPP = (obj) => {
  return axios({
    method: 'get',
    url: '/wechat/register/untieAPP',

  })
}


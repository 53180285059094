import axios from 'axios'
import router from "../router"
import { Toast } from "vant";

let baseUrl;
if (process.env.NODE_ENV == "development") {
  baseUrl = "/api/reader-api";
} else {
  baseUrl = "/api/reader-api";
}
var myaxios = axios.create({
  // baseURL: 'http://192.168.10.200:8086/api',
  baseURL: baseUrl,
  timeout: 100000,
  responseType: 'json',
  withCredentials: false, // 表示跨域请求时是否需要使用凭证
  // headers: {
  //   // token: store.state.axios.token,
  //   'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
  // }
})
//请求拦截器
myaxios.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token')
    // if (token && config.url != '/wechat/query') { // 判断是否存在token，如果存在的话，则每个http header都加上token 
    //   config.headers.reader_token = token  //请求头加上token
    // } else {
    //   config.headers.reader_token = ''
    // }
    // if (config.url.startsWith('/wechat/query')) {
    //   config.url = '/push-api' + config.url;
    //   //微信推送
    // }
    config.headers.reader_token = token
    return config
  },
  err => {
    return Promise.reject(err)
  })

//响应拦截器  

myaxios.interceptors.response.use(
  response => {

    //拦截响应，做统一处理 
    if (response.data.code) {
      switch (response.data.code) {
        // case 401:
        //   ElementUI.Message({
        //     message: response.data.msg,
        //     type: 'error',
        //     duration: 2000,
        //   }); break;
        case 522:
          Toast.fail(response.data.msg)
          // setTimeout(() => {
          //   window.location.href = `http://sso.gttss.com/?appCode=00001001&platformCode=00001102&openId=${localStorage.getItem('openId')}`;
          //   // window.location.href = `http://192.168.12.11:8080/?appCode=00001001&platformCode=00001102&code=${sessionStorage.getItem('code')}`
          //   // window.location.href = `http://192.168.12.11:8080/?appCode=00001001&code=${sessionStorage.getItem('code')}`;
          // }, 3000);
          if (response.data.data.ssoUrl) {
            setTimeout(() => {
              window.location.href = `${response.data.data.ssoUrl}?appCode=00001001&platformCode=00001102&openId=${localStorage.getItem('openId')}`;
            }, 2500);
          }
          break;
      }
    }
    return response
  },
  //接口错误状态处理，也就是说无响应时的处理
  error => {
    if (error && error.response) {
      if (error) {
        console.log(error);
      }
      switch (error.response.status) {
        case 400: error.message = '请求错误(400)'; break;
        case 401: error.message = '未授权，请重新登录(401)'; break;
        case 403: error.message = '拒绝访问(403)'; break;
        case 404: error.message = '请求出错(404)'; break;
        case 408: error.message = '请求超时(408)'; break;
        case 500: error.message = '服务器错误(500)'; break;
        case 501: error.message = '服务未实现(501)'; break;
        case 502: error.message = '网络错误(502)'; break;
        case 503: error.message = '服务不可用(503)'; break;
        case 504: error.message = '网络超时(504)'; break;
        case 505: error.message = 'HTTP版本不受支持(505)'; break;
        default: error.message = `连接出错(${err.response.status})!`;
      }
    } else {
      error.message = '连接服务器失败!'
    }
    // ElementUI.Message({
    //   message: '会话过期，请重新登录',
    //   type: 'error'
    // });
    // setTimeout(() => {
    //   router.push("/")
    //   sessionStorage.clear()
    // }, 3000);
    return Promise.resolve(error);// 返回接口返回的错误信息
  })




export default myaxios
